// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from 'react'
import {Modal, Button, Row, ModalBody, Form} from 'react-bootstrap';
import Axios from 'axios';
import Url from "components/Url";

const CheckStudentProfileStatus = (props) => {

    const {staticModal = false} = props;
    //this component is standalone component that checks for the student profile completion status and shows the dialog box accordingly
    const [show, setShow] = useState(false);
    const [updateMobile, setUpdateMobile] = useState(false);
    const [phoneNo, setPhoneNo] = useState();

    const token = localStorage.getItem("stutoken");
	const studentId = localStorage.getItem("studentId");	
    const applicantId = localStorage.getItem("applicantid");    
    const [validated, setValidated] = useState(false);

    const url1 = Url();
    const s3 = url1.s3;
    const url = url1.url;

    useEffect(() => {
        
        if(localStorage.getItem("profileStatus") !== 'undefined' && localStorage.getItem("profileStatus") == '0' ){
            console.log("setting show modal");
            setShow(true);
        }

        if(localStorage.getItem("cookie_eurui38398DJEU393jfjjr") !== 'undefined' && localStorage.getItem("cookie_eurui38398DJEU393jfjjr") == 0 ){
            setShow(false);
            setUpdateMobile(true);
        }else{
            localStorage.removeItem("cookie_eurui38398DJEU393jfjjr");
        }

    },[])

	const updateMobileNo = () => {

        if(typeof(phoneNo) === "undefined" || phoneNo.length !== 10){
            alert("Enter correct mobile number") 
            return;
        }

		let apiURL = url + "updateMobile";
		Axios.post(
			apiURL,
			{phoneNo,applicantId},
			{headers: {
					"Content-type": "Application/json",
					"Authorization": `Bearer ${token}`,
					"X-USER-ID": studentId
					}   
				}
		  )
		  .then((response) => {
			  //var response = response.data.virtualInternshipList;
			  //console.log(response.data);
			  if(response.data.message = "Records_updated_successfully"){
                localStorage.removeItem("cookie_eurui38398DJEU393jfjjr");
                setUpdateMobile(false);
                window.location.reload();
              }
			},
			(error) => {
			  var status = error.response.status
			}
		  );			
	};

    return (
        <Fragment>

            <Modal show={show} onHide={(e) => {setShow(false)} }
                  backdrop={staticModal ? "static" : true} 
                  keyboard={!staticModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                <Modal.Header className='py-0 py-2' closeButton={!staticModal}>
                    <Modal.Title className=' d-flex fs-3 justify-content-center w-100' id="contained-modal-title-vcenter">
                        Incomplete Profile
                    </Modal.Title>
                </Modal.Header>            
                <ModalBody  className="py-6 d-flex justify-content-center flex-column">
                     <div className="fs-3 fw-bold text-center pb-5">To find and apply for internships, please <span className='text-secondary'>Complete Your Profile First</span></div>
                    <Row className='d-flex justify-content-center w-100'>
                     <Button href="/student-profile" className="btn w-50 btn-primary">Go To Profile</Button>   
                     </Row>
                </ModalBody>
            </Modal>

            {updateMobile && (
            <Modal show={updateMobile} onHide={(e) => {setUpdateMobile(false)} }
                  backdrop={"static"} 
                  keyboard={true}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                <Modal.Header className='py-0 py-2'>
                    <Modal.Title className=' d-flex fs-3 justify-content-center w-100' id="contained-modal-title-vcenter">
                        Update Mobile Number
                    </Modal.Title>
                </Modal.Header>            
                <ModalBody  className="py-6 d-flex justify-content-center flex-column">
                     <div className="fs-3 fw-bold text-center pb-5">
                     <Form.Group className="mb-3">
                        <Form.Control
                        type="text"
                        placeholder="Enter Mobile No."
                        id="phoneNo"
                        name="phoneNo"
                        value={phoneNo}
                        maxLength={10}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        required
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        }}                        
                        pattern="^[0-9]{10}$"
                        isInvalid={
                            validated && phoneNo && !phoneNo.match(/^[0-9]{10}$/)
                        }
                        />
                        <Form.Control.Feedback type="valid">
                        Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                        Please enter a valid 10-digit mobile number.
                        </Form.Control.Feedback>
                    </Form.Group>
                     </div>
                    <Row className='d-flex justify-content-center w-100'>
                     <Button onClick={updateMobileNo} className="btn w-50 btn-primary">Update Mobile Number</Button>   
                     </Row>
                </ModalBody>
            </Modal>)}
            )
        </Fragment>
    )
}

export default CheckStudentProfileStatus;