import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createRef,
  Fragment,
  useRef,
} from "react";
import {
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Accordion,
  Card,
  Modal,
  ListGroup,
  Button,
  Collapse
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import { FiChevronDown, FiChevronUp, FiUser, FiX,FiGrid } from "react-icons/fi";
import { HiDocumentDuplicate, HiCheckCircle } from "react-icons/hi";
import { BiArrowBack } from "react-icons/bi";
import { BsCircleFill, BsCheckCircleFill, BsRobot, BsFillEyeFill,BsXLg,BsFillQuestionCircleFill } from "react-icons/bs";
import "./ProfessionalSummaryAICard.css";
import {FcLeft} from "react-icons/fc";
import { Bold } from "react-feather";

const ProfessionalSummaryAICard = (props) => {
  {
    /*************************AI Suggestions modal - start **************/
  }

  const {suggestions,
    suggestionsWait,
    getSuggestions,
    setSuggestionsSearchText,
    setProfessionalSummary,
    suggestionsSearchText,
    setShowSuggestions,
    showSuggestions,
    handleSuggestionLineClick
   } = props;

  const tooltip = (
    <Tooltip id="tooltip">
      Use 4-5 keywords like "Software Engineer,5 years experience, ReactJS, Mysql" or "Project Manager, 12 years experience, Banking and financial solutions"
    </Tooltip>
  );
  const [open, setOpen] = useState(false);
  
  return (
  <Fragment>
   <Card className={showSuggestions ? `visible h-100 ai-suggestion-card` : "invisible ai-suggestion-card"}>
  <Card.Body className="overflow-auto p-3 p-sm-4">
    <Row className="border-bottom pb-3">
      <Form.Group
        as={Col}
        lg={12}
        className="d-sm-flex align-items-center"
      >
       {/* Search box - start */}
<Col xs={12} md={9} className="mt-2 mt-sm-0">
  <Form.Control
    type="text"
    placeholder="Software engineer, 5 years experience, ReactJS, MySQL"
    id="suggestionsSearchText"
    name="suggestionsSearchText"
    value={suggestionsSearchText}
    onChange={(e) => setSuggestionsSearchText(e.target.value || "")}
    className="form-control-lg rounded-3 shadow-sm border-0"
    style={{
      fontSize: "1rem",
      padding: "12px 16px",
      backgroundColor: "#f8f9fa",
      transition: "all 0.3s ease-in-out",
    }}
    onFocus={(e) => e.target.style.border = "2px solid #0056b3"}
    onBlur={(e) => e.target.style.border = "none"}
  />
</Col>
{/* Search box - end */}


        <Col xs={12} md={3} className="ms-2 mt-2 mt-sm-0 d-flex justify-content-between align-items-center">
          {/* Search button or spinner */}
          {suggestionsWait ? (
            <Spinner animation="grow" className="ms-3" />
          ) : (
            <Button variant="outline-primary" onClick={getSuggestions} className="d-flex align-items-center">
              <BsRobot size={15} style={{ cursor: "pointer" }} />
              <span className="ms-2" style={{ fontSize: "10px", fontWeight: "bold",width:"50px" }}>Generate</span>
            </Button>
          )}
          {/* Help Button */}
          <span
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="ms-2 text-muted"
          >
            <BsFillQuestionCircleFill size={18} />
          </span>
        </Col>
      </Form.Group>

      {/* Collapse Help Text */}
      <Col xs={12} sm="12">
        <Collapse in={open}>
          <div id="example-collapse-text">
            <p className="small pt-2 text-muted">
              Use 4-5 keywords like "Software Engineer, 5 years experience, ReactJS, MySQL" or "Project Manager, 12 years experience, Banking and Financial Solutions". <span className="fst-italic">From the suggestions, each line is selectable. To copy the complete paragraph, click on the blue arrow.</span>
            </p>
          </div>
        </Collapse>
      </Col>
    </Row>

    {/* Suggestions List */}
    {suggestions.length > 0 ? (
      <Fragment>
        {suggestions.map((suggestion, index) => (
          <Row key={index} className="border-bottom py-2">
            <Col className="d-flex align-items-top" style={{ fontSize: ".9rem" }}>
              <Col xs={2} sm="1" className="d-flex justify-content-center">
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setProfessionalSummary(suggestions[index])}
                >
                  <FcLeft size={20} />
                </span>
              </Col>
              <Col xs={10} sm="11">
                {suggestion.split(".").map((suggestionLine, idx) => (
                  <span
                    key={idx}
                    className="suggestionLine"
                    onClick={handleSuggestionLineClick}
                    style={{ cursor: "pointer" }}
                  >
                    {suggestionLine + "."}
                  </span>
                ))}
              </Col>
            </Col>
          </Row>
        ))}
      </Fragment>
    ) : (
      <Fragment>
        <ListGroup variant="flush">
          <ListGroup.Item className="text-center text-muted">
            Add keywords, click on the IGBot, and let IGBot create a Professional Summary for you..!!!
          </ListGroup.Item>
        </ListGroup>
      </Fragment>
    )}
  {/* Close Button */}
  <span
      className="position-absolute top-0 end-0 m-3 text-dark"
      style={{ cursor: "pointer", paddingLeft: "15px", top: "15px" }} // Adjust 'top' here to move it slightly up
      onClick={() => setShowSuggestions(false)}
    >
      <BsXLg size={20} />
    </span>
  </Card.Body>
</Card>

  </Fragment>
  
  );
};

export default ProfessionalSummaryAICard;
