import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop.css";

const References = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside a valid destination
    if (!destination) return;

    // Reorder the fields array
    const reorderedFields = Array.from(fields);

    const [movedItem] = reorderedFields.splice(source.index, 1);

    reorderedFields.splice(destination.index, 0, movedItem);

    // Clear the current fields
    remove(); // Clear all fields

    // Append the reordered fields
    reorderedFields.forEach((field) => {
      append(field);
    });
  };

  const handleAddReference = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 250));
      append({});
    } catch (error) {
      console.error("Error adding reference:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="resume-builder__section">
      <h2>References</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="references-list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="references-list"
            >
              {fields.map((field, index) => {
                const { referee_name, designation, company, contact } =
                  watch("references")?.[index] || {};
                return (
                  <Draggable
                    key={field.key}
                    draggableId={field.key.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cx("resume-builder__section__card p-0")}
                      >
                        <input
                          type="hidden"
                          id={`references[${index}].id`}
                          name={`references[${index}].id`}
                          defaultValue={field.id}
                          {...register(`references[${index}].id`)}
                        />
                        <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
                          <Accordion.Item
                            as={"div"}
                            variant="link"
                            eventKey={`${field.key}`}
                            className="resume-builder__section__card--title"
                          >
                            <Accordion.Header>
                              <div>
                                <p className="m-0">
                                  {referee_name || "(No Name Specified)"}
                                </p>
                                <Label className="mb-0">
                                  {designation && `(${designation})`}
                                </Label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body eventKey={`${field.key}`}>
                              <>
                                <Row className="mt-2 mb-3">
                                  <Col>
                                    <Label>Referent's Full Name</Label>
                                    <Controller
                                      control={control}
                                      name={`References[${index}].ref_name`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                      }) => (
                                        <InputField
                                          stretch
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col>
                                    <Label>Company</Label>
                                    <Controller
                                      control={control}
                                      name={`References[${index}].company_name`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                      }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter Company Name"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Label htmlFor="Phone">Phone</Label>
                                    <Controller
                                      control={control}
                                      name={`References[${index}].phone_no`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                      }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter Phone Number"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col>
                                    <Label htmlFor="display_email">Email</Label>
                                    <Controller
                                      control={control}
                                      name={`References[${index}].ref_email`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                      }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter Email"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Button
                          variant="link"
                          disabled={deleting === index}
                          className="resume-builder__section__card--delete"
                          onClick={() => {
                            setDeleting(index);
                            remove(index);
                            setDeleting(null);
                          }}
                        >
                          {deleting === index ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <FiTrash2 size={20} />
                          )}
                        </Button>

                        {/* Apply dragHandleProps only to the dots */}
                        <div
                          {...provided.dragHandleProps} // Apply drag handle only to the dots
                          className="drag-handle"
                        >
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="tooltip-text">Click and drag to move</span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="contained"
        className={`btn-add ${loading ? "loading" : ""}`}
        onClick={handleAddReference}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <>
            <FiPlus size={20} /> {fields.length < 1 ? " Add reference" : " Add one more reference"}
          </>
        )}
      </Button>
    </div>
  );
};

export default References;
