// import node module libraries
import React, { useContext, useRef, useEffect, Fragment } from 'react';
import SimpleBar from 'simplebar-react';

// import sub custom components
import ChatHeader from './ChatHeader';
import Message from './Message';
import ChatFooter from './ChatFooter';

// import context file
import { ChatContext } from 'context/Context';

// import hook file
import useChatOperations from 'hooks/useChatOperations';

const ChatBox = (props) => {
	const { name, id, chats, setId, setMessage, message, postChatMessage } = props;
	// const {
	// 	ChatState: { activeThread }
	// } = useContext(ChatContext);
	// const { getThreadMessages } = useChatOperations();
	// const thread = getThreadMessages(activeThread.messagesId);

	// Auto scroll to bottom when the new chat has been added.
	const messagesEndRef = useRef(null);
	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest'
		});
	};
	useEffect(scrollToBottom);
	let lastDate = null;
	return (
		<div
			className=" w-100 vh-100"
		>
			{id ?
			<ChatHeader setId={setId} name={name} />
			:
			<div className='w-100 text-center d-none d-sm-block'>
				<h3>Please select chat</h3>
			</div>
			}
			<SimpleBar className="vh-100" style={{ maxHeight: '50vh' }}>
				<div className="px-4 mb-5 py-4 h-100 messages-container">
							<Fragment>
								{/* {lastDate !== "8/12/1999" && (
									<div className="text-center"><span>"8/12/1999"</span></div>
								)}
								{(() => {
									lastDate = "8/12/1999"
								})()} */}
								<Message chats={chats}  />
							</Fragment>
				</div>
				<div ref={messagesEndRef} />
			</SimpleBar>
			<ChatFooter setMessage={setMessage} message={message} postChatMessage={postChatMessage} />
		</div>
	);
};
export default ChatBox;
