import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop.css";


const Education = ({ watch, register, fields, append, remove, control, update }) => {
	const [deleting, setDeleting] = useState(null);
	const education = watch("education");
	const [loading, setLoading] = useState(false); // Loading state for adding education

	const onDragEnd = (result) => {
		const { source, destination } = result;

		// If dropped outside a valid destination
		if (!destination) return;

		// Reorder the fields array
		const reorderedFields = Array.from(fields);
		const [movedItem] = reorderedFields.splice(source.index, 1);
		reorderedFields.splice(destination.index, 0, movedItem);

		// Clear the current fields
		remove(); // Clear all fields

		// Append the reordered fields
		reorderedFields.forEach((field) => {
			append(field);
		});
	};

	const handleAddEducation = async () => {
		setLoading(true); // Set loading to true when adding education
		try {
			// Simulate an async operation for adding education, like an API call
			await new Promise((resolve) => setTimeout(resolve, 250));
			append({});
		} catch (error) {
			console.error("Error adding education:", error);
		} finally {
			setLoading(false); // Set loading to false after the addition
		}
	};


	return (
		<div className="resume-builder__section">
			<h2>Education</h2>
			<Label as="p">
				A varied education on your resume sums up the value that your learnings
				and background will bring to job.
			</Label>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="education-list">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="education-list"
						>
							{fields.map((field, index) => {
								const { institute_name, degree, start_date, end_date } =
									education?.[index] || {};
								const displayDate = [];
								if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
								if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
								return (
									<Draggable
										key={field.key}
										draggableId={field.key.toString()}
										index={index}
									>
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												className="resume-builder__section__card p-0"
											>
												<input
													type="hidden"
													id={`education[${index}].id`}
													name={`education[${index}].id`}
													defaultValue={field.id}
													{...register(`education[${index}].id`)}
												/>
												<Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
													<Accordion.Item
														as={"div"}
														variant="link"
														eventKey={`${field.key}`}
														className="resume-builder__section__card--title"
													>
														<Accordion.Header className="w-100">
															<div className="m-0">
																<p className="m-0">
																	{institute_name && degree
																		? `${degree} at ${institute_name}`
																		: degree || institute_name || "(Not Specified)"}
																</p>
																{displayDate.length > 0 && (
																	<Label className="mb-0">{displayDate.join(" - ")}</Label>
																)}
															</div>
														</Accordion.Header>
														<Accordion.Body eventKey={`${field.key}`}>
															{/* Form Fields Here */}
															<Accordion.Body eventKey={`${field.key}`}>
																<>
																	<Row>
																		<Col>
																			<Controller
																				control={control}
																				name={`education[${index}].end_date`}
																				render={({
																					field: { onChange, value, name, ref }
																				}) => (
																					<>
																						<Switch
																							checked={value === null}
																							onChange={(e) =>
																								onChange(e.target.checked ? null : "")
																							}
																							color="primary"
																						/>{" "}
																						<Label as="span">Currently study here</Label>
																					</>
																				)}
																			/>
																		</Col>
																	</Row>
																	<Row className="mb-3">
																		<Col>
																			<Label>Institute</Label>
																			<Controller
																				control={control}
																				name={`education[${index}].institute_name`}
																				render={({
																					field: { onChange, onBlur, value, name, ref },
																					fieldState: { invalid, isTouched, isDirty, error },
																					formState,
																				}) => (
																					<InputField
																						stretch
																						placeholder="Enter Institute Name"
																						onBlur={onBlur} // notify when input is touched
																						onChange={onChange} // send value to hook form
																						checked={value}
																						inputRef={ref}
																					/>
																				)}
																			/>
																		</Col>
																		<Col>
																			<Label>Degree</Label>
																			<Controller
																				control={control}
																				name={`education[${index}].degree`}
																				render={({
																					field: { onChange, onBlur, value, name, ref },
																					fieldState: { invalid, isTouched, isDirty, error },
																					formState,
																				}) => (
																					<InputField
																						stretch
																						placeholder="Enter Degree"
																						onBlur={onBlur} // notify when input is touched
																						onChange={onChange} // send value to hook form
																						checked={value}
																						inputRef={ref}
																					/>
																				)}
																			/>
																		</Col>
																	</Row>
																	<Row className="mb-3">
																		<Col>
																			<Row>
																				<Col xs={6} className="pr-1">
																					<Label>Start Date</Label>
																					<Controller
																						control={control}
																						name={`education[${index}].start_date`}
																						render={({
																							field: { onChange, onBlur, value, name, ref },
																							fieldState: {
																								invalid,
																								isTouched,
																								isDirty,
																								error,
																							},
																							formState,
																						}) => (
																							<InputField
																								type="date"
																								stretch
																								onBlur={onBlur} // notify when input is touched
																								onChange={onChange} // send value to hook form
																								checked={value}
																								inputRef={ref}
																							/>
																						)}
																					/>
																				</Col>
																				<Col xs={6} className="pl-1">
																					<Label>End Date</Label>
																					<Controller
																						control={control}
																						name={`education[${index}].end_date`}
																						render={({
																							field: { onChange, onBlur, value, name, ref },
																							fieldState: {
																								invalid,
																								isTouched,
																								isDirty,
																								error,
																							},
																							formState,
																						}) =>
																							end_date === null ? (
																								<InputField
																									stretch
																									value="Present"
																									readOnly
																								/>
																							) : (
																								<InputField
																									type="date"
																									min={start_date}
																									stretch
																									onBlur={onBlur} // notify when input is touched
																									onChange={onChange} // send value to hook form
																									checked={value}
																									inputRef={ref}
																								/>
																							)
																						}
																					/>
																				</Col>
																			</Row>
																		</Col>
																		<Col>
																			<Label>City</Label>
																			<Controller
																				control={control}
																				name={`education[${index}].city`}
																				render={({
																					field: { onChange, onBlur, value, name, ref },
																					fieldState: { invalid, isTouched, isDirty, error },
																					formState,
																				}) => (
																					<InputField
																						stretch
																						placeholder="Enter city"
																						onBlur={onBlur} // notify when input is touched
																						onChange={onChange} // send value to hook form
																						checked={value}
																						inputRef={ref}
																					/>
																				)}
																			/>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<Label className="font-weight-bold text-dark" >Description</Label>
																			<Controller
																				control={control}
																				name={`education[${index}].Description`}
																				render={({
																					field: { onChange, onBlur, value, name, ref },
																					fieldState: { invalid, isTouched, isDirty, error },
																					formState,
																				}) => (
																					<RTE
																						value={value}
																						onBlur={onBlur} // notify when input is touched
																						onChange={onChange} // send value to hook form
																						checked={value}
																						inputRef={ref}
																					/>
																				)}
																			/>
																		</Col>
																	</Row>
																</>
															</Accordion.Body>

														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
												<div className="d-flex align-items-center justify-content-between p-2">
													{/* Delete Button */}
													<Button
														variant="link"
														disabled={deleting === index}
														className="resume-builder__section__card--delete"
														onClick={async () => {
															try {
																setDeleting(index);
																if (field.id) {
																	await Api.delete(`${ENDPOINTS.DELETE_EDU_DETAIL}/${field.id}`);
																}
																remove(index);
															} finally {
																setDeleting(null);
															}
														}}
													>
														{deleting === index ? (
															<CircularProgress size={20} color="primary" />
														) : (
															<FiTrash2 size={20} />
														)}
													</Button>

													{/* Drag Handle */}
													<div
														{...provided.dragHandleProps}
														className="drag-handle"
													>
														<span className="dot"></span>
														<span className="dot"></span>
														<span className="dot"></span>
														<span className="dot"></span>
														<span className="dot"></span>
														<span className="dot"></span>
														<span className="tooltip-text">Click and drag to move</span>
													</div>

												</div>
											</div>
										)}
									</Draggable>

								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{/* <Row className="mb-3">
					<Col>
						<Button className="text-info fs-4" variant="link" type="button" onClick={() => append({})}>
							<FiPlus />
							{fields.length < 1 ? " Add education" : " Add one more education"}
						</Button>
					</Col>
				</Row> */}
			<Button
				variant="contained"
				className={`btn-add ${loading ? "loading" : ""}`}
				onClick={handleAddEducation}
				disabled={loading}
			>
				{loading ? (
					<CircularProgress size={24} color="inherit" />
				) : (
					<>
						<FiPlus size={20} /> {fields.length < 1 ? "Add Education" : "Add One More Education"}
					</>
				)}
			</Button>
		</div>
	);
};

export default Education;
