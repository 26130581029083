import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop.css";

const Internships = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const internships = watch("internships");
  const [loading, setLoading] = useState(false);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside a valid destination
    if (!destination) return;

    // Reorder the fields array
    const reorderedFields = Array.from(fields);
    const [movedItem] = reorderedFields.splice(source.index, 1);
    reorderedFields.splice(destination.index, 0, movedItem);

    // Clear the current fields
    remove(); // Clear all fields

    // Append the reordered fields
    reorderedFields.forEach((field) => {
      append(field);
    });
  };

  const handleAddInternship = async () => {
    setLoading(true); // Set loading to true when adding education
    try {
      // Simulate an async operation for adding education, like an API call
      await new Promise((resolve) => setTimeout(resolve, 250));
      append({});
    } catch (error) {
      console.error("Error adding education:", error);
    } finally {
      setLoading(false); // Set loading to false after the addition
    }
  };

  return (
    <div className="resume-builder__section">
      <h2>Internships</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="internships-list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="internships-list"
            >
              {fields.map((field, index) => {
                const { employer_name, job_title, start_date, end_date } =
                  internships?.[index] || {};
                const displayDate = [];
                if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
                if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
                return (
                  <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cx("resume-builder__section__card p-0")}
                      >
                        <input
                          type="hidden"
                          id={`internships[${index}].id`}
                          name={`internships[${index}].id`}
                          defaultValue={field.id}
                          {...register(`internships[${index}].id`)}
                        />
                        <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
                          <Accordion.Item
                            as={"div"}
                            variant="link"
                            eventKey={`${field.key}`}
                            className="resume-builder__section__card--title"
                          >
                            <Accordion.Header className="w-100">
                              <div className="m-0">
                                <p className="m-0">
                                  {employer_name && job_title
                                    ? `${job_title} at ${employer_name}`
                                    : job_title || employer_name || "(Not Specified)"}
                                </p>
                                {displayDate.length > 0 && (
                                  <Label className="mb-0">{displayDate.join(" - ")}</Label>
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body eventKey={`${field.key}`}>
                              <>
                                <Row>
                                  <Col>
                                    <Controller
                                      control={control}
                                      name={`internships[${index}].end_date`}
                                      render={({ field: { onChange, value, name, ref } }) => (
                                        <>
                                          <Switch
                                            checked={value === null}
                                            onChange={(e) => onChange(e.target.checked ? null : "")}
                                            color="primary"
                                          />{" "}
                                          <Label as="span">Currently internship</Label>
                                        </>
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Label>Employer</Label>
                                    <Controller
                                      control={control}
                                      name={`internships[${index}].employer_name`}
                                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter Employer Name"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col>
                                    <Label>Job Title</Label>
                                    <Controller
                                      control={control}
                                      name={`internships[${index}].job_title`}
                                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter Job Title"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Row>
                                      <Col xs={6} className="pr-1">
                                        <Label>Start Date</Label>
                                        <Controller
                                          control={control}
                                          name={`internships[${index}].start_date`}
                                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <InputField
                                              type="date"
                                              stretch
                                              onBlur={onBlur}
                                              onChange={onChange}
                                              checked={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      </Col>
                                      <Col xs={6} className="pl-1">
                                        <Label>End Date</Label>
                                        <Controller
                                          control={control}
                                          name={`internships[${index}].end_date`}
                                          render={({ field: { onChange, onBlur, value, name, ref } }) =>
                                            end_date === null ? (
                                              <InputField stretch value="Present" readOnly />
                                            ) : (
                                              <InputField
                                                type="date"
                                                min={start_date}
                                                stretch
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                checked={value}
                                                inputRef={ref}
                                              />
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Label>City</Label>
                                    <Controller
                                      control={control}
                                      name={`internships[${index}].city`}
                                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter city"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Label>Description</Label>
                                    <Controller
                                      control={control}
                                      name={`internships[${index}].description`}
                                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <RTE
                                          value={value}
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Button
                          variant="link"
                          disabled={deleting === index}
                          className="resume-builder__section__card--delete"
                          onClick={async () => {
                            try {
                              setDeleting(index);
                              if (field.id) {
                                await Api.delete(`${ENDPOINTS.DELETE_INTERNSHIP}/${field.id}`);
                              }
                              remove(index);
                            } finally {
                              setDeleting(null);
                            }
                          }}
                        >
                          {deleting === index ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <FiTrash2 size={20} />
                          )}
                        </Button>
                        {/* Drag Handle */}
                        <div
                          {...provided.dragHandleProps}
                          className="drag-handle"
                        >
                           <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="tooltip-text">Click and drag to move</span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="contained"
        className={`btn-add ${loading ? "loading" : ""}`}
        onClick={handleAddInternship}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <>
            <FiPlus size={20} /> {fields.length < 1 ? "Add Internship" : "Add One More Internship"}
          </>
        )}
      </Button>
    </div>
  );
};

export default Internships;
