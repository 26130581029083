import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Courses = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const [loading, setLoading] = useState(false);
  const Courses = watch("courses");
  
  // Handle drag and drop end
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedFields = Array.from(fields);
    const [movedItem] = reorderedFields.splice(source.index, 1);
    reorderedFields.splice(destination.index, 0, movedItem);

    remove();  // Clear all fields
    reorderedFields.forEach((field) => append(field));  // Append reordered fields
  };
  const handleAddCourse = async () => {
		setLoading(true); // Set loading to true when adding activity
		try {
			// Simulate an async operation for adding activity, like an API call
			await new Promise((resolve) => setTimeout(resolve, 250));
			append({});
		} catch (error) {
			console.error("Error adding activity:", error);
		} finally {
			setLoading(false); // Set loading to false after the addition
		}
	};

  return (
    <div className="resume-builder__section">
      <h2>Courses</h2>

      {/* DragDropContext to enable drag-and-drop functionality */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="courses-list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="resume-builder__section__cards-container"
            >
              {fields.map((field, index) => {
                const { course_name, institute_name, start_date, end_date } = Courses?.[index] || {};
                const displayDate = [];
                if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
                if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));

                return (
                  <Draggable key={field.key} draggableId={field.key} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps} // Apply draggable props to the entire course card
                        className={cx("resume-builder__section__card p-0")}
                      >
                        {/* Drag Handle (6 dots) */}
                        <div
                          {...provided.dragHandleProps}  // Attach drag handle only to the dots
                          className="drag-handle"
                        >
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="tooltip-text">Click and drag to move</span>
                        </div>

                        <input
                          type="hidden"
                          id={`courses[${index}].id`}
                          name={`courses[${index}].id`}
                          defaultValue={field.id}
                          {...register(`courses[${index}]`)}
                        />
                        <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
                          <Accordion.Item as={"div"} variant="link" eventKey={`${field.key}`} className="resume-builder__section__card--title">
                            <Accordion.Header className="w-100">
                              <div className="m-0">
                                <p className="m-0">
                                  {course_name && institute_name
                                    ? `${course_name} at ${institute_name}`
                                    : institute_name || course_name || "(Not Specified)"}
                                </p>
                                {displayDate.length > 0 && (
                                  <Label className="mb-0">{displayDate.join(" - ")}</Label>
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body eventKey={`${field.key}`}>
                              <Row>
                                <Col>
                                  <Controller
                                    control={control}
                                    name={`courses[${index}].end_date`}
                                    render={({ field: { onChange, value, name, ref } }) => (
                                      <>
                                        <Switch
                                          checked={value === null}
                                          onChange={(e) => onChange(e.target.checked ? null : "")}
                                          color="primary"
                                        />
                                        <Label as="span">Ongoing course</Label>
                                      </>
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Label className="mt-1">Course</Label>
                                  <Controller
                                    control={control}
                                    name={`courses[${index}].course_name`}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                      <InputField
                                        stretch
                                        placeholder="Enter Course Name"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        checked={value}
                                        inputRef={ref}
                                      />
                                    )}
                                  />
                                </Col>
                                <Col>
                                  <Label>Institution</Label>
                                  <Controller
                                    control={control}
                                    name={`courses[${index}].institute_name`}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                      <InputField
                                        stretch
                                        placeholder="Enter institute name"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        checked={value}
                                        inputRef={ref}
                                      />
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Row>
                                    <Col xs={6} className="pr-1">
                                      <Label>Start Date</Label>
                                      <Controller
                                        control={control}
                                        name={`courses[${index}].start_date`}
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                          <InputField
                                            type="date"
                                            stretch
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            checked={value}
                                            inputRef={ref}
                                          />
                                        )}
                                      />
                                    </Col>
                                    <Col xs={6} className="pl-1">
                                      <Label>End Date</Label>
                                      <Controller
                                        control={control}
                                        name={`courses[${index}].end_date`}
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                          end_date === null ? (
                                            <InputField stretch value="Present" readOnly />
                                          ) : (
                                            <InputField
                                              type="date"
                                              min={start_date}
                                              stretch
                                              onBlur={onBlur}
                                              onChange={onChange}
                                              checked={value}
                                              inputRef={ref}
                                            />
                                          )
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Label>City</Label>
                                  <Controller
                                    control={control}
                                    name={`courses[${index}].city`}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                      <InputField
                                        stretch
                                        placeholder="Enter city"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        checked={value}
                                        inputRef={ref}
                                      />
                                    )}
                                  />
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Button
                          variant="link"
                          disabled={deleting === index}
                          className="resume-builder__section__card--delete"
                          onClick={async () => {
                            try {
                              setDeleting(index);
                              if (field.id) {
                                await Api.delete(`${ENDPOINTS.DELETE_COURSE}/${field.id}`);
                              }
                              remove(index);
                            } finally {
                              setDeleting(null);
                            }
                          }}
                        >
                          {deleting === index ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <FiTrash2 size={20} />
                          )}
                        </Button>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder} {/* Placeholder to maintain space when dragging */}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Row className="mb-3">
        <Col>
         <Button
                variant="contained"
                className={`btn-add ${loading ? "loading" : ""}`}
                onClick={handleAddCourse}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>
                    <FiPlus size={20} /> {fields.length < 1 ? "Add Course" : "Add One More Course"}
                  </>
                )}
              </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Courses;