import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row } from "react-bootstrap";
import {
	pdf,
	Document as RendererDocument,
	Page as RendererPage,
	Text,
} from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsSrc from "pdfjs-dist/build/pdf.worker";
import { Html } from "react-pdf-html";
import { Font } from "@react-pdf/renderer";
import { FiChevronLeft, FiChevronRight, FiDownload, FiGrid } from "react-icons/fi";
import moment from "moment";
import { Button } from "../foundation";
// import "./fonts";
import CircularProgress from "@material-ui/core/CircularProgress";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const useA4Scale = (ref) => {

	const [scale, setScale] = useState(0);
	const handleResize = () => {
		if (ref.current) {
			const parentEl = ref.current.parentElement;
			const scaleHeight =
				(parentEl.clientHeight - 50) / ref.current.clientHeight;
			const scaleWidth = (parentEl.clientWidth - 30) / ref.current.clientWidth;
			setScale(Math.min(scaleHeight, scaleWidth));
		}
	};
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return [scale, handleResize];
};


export const htmlStyles = {
	body: {
		fontFamily: "Roboto",
		margin: 0,
	},
	p: {
		margin: 0,
		fontSize: "10px",
		"page-break-inside": "avoid",
	},
	ul: {
		margin: 0,
	},
	ol: {
		margin: 0,
	},
	li: {
		fontSize: "10px",
		margin: 0,
		"page-break-inside": "avoid",
	},
};

export const renderHTMLContent = (content, customStyles = {}) => (
	<Html stylesheet={[htmlStyles, customStyles]}>
		{`<html>
        <head>
        </head>
        <body>${content}</body>
      </html>`}
	</Html>
);
const debounce = (callback, wait) => {
	let timeoutId = null;
	return (...args) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			callback.apply(null, args);
		}, wait);
	};
};

const PdfLoader = () => (
	<div
		style={{
			height: "222mm",
			backgroundColor: "#ffffff",
			display: "flex",

		}}
	>
		<CircularProgress />
	</div>
);

export const getDisplayDate = (start_date, end_date, format = "MMM YYYY") => {
	const displayDate = [];
	if (start_date) displayDate.push(moment(start_date).format(format));
	if (end_date) displayDate.push(moment(end_date).format(format));
	else displayDate.push("Present");
	return displayDate.join(" - ");
};

export const TemplatePage = ({ data, children }) => {
	const pageRef = useRef();
	const [file, setFile] = useState("");
	const [numPages, setNumPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [scale, handleResize] = useA4Scale(pageRef);
	const [showSelectTemplate, setShowSelectTemplate] = useState(false);
	const [MobileScr, setMobilescr] = useState(
		window.innerWidth > 576 ? false : true
	);
	var pageStyle = [];
	//Following styles will be used according to device. if the resume preview is not rendering properly
	//on any of the device adjust the zoom (scale is used in desktop and % in mobile view)
	if (MobileScr) {
		pageStyle = {
			width: "158mm",
			height: "222mm",
			zoom: "60%",
		};
	} else {
		pageStyle = {
			width: "158mm",
			height: "215mm",
			//zoom: scale,
			zoom: "72.5%",
			position: "absolute",
			top: "75px",
			paddingTop: "2px",
		};
	}
	const renderPdf = useCallback(
		debounce(
			() =>
				pdf(children)
					.toBlob()
					.then((url) => setFile(URL.createObjectURL(url))),
			1000
		),
		[children]
	);
	useEffect(() => {
		renderPdf();
	}, [children]);
	return (
		<>
			<div
				style={pageStyle}
				ref={pageRef}
			>
				<Document
					file={file}
					loading={PdfLoader}
					noData={PdfLoader}
					onLoadSuccess={(d) => {
						setNumPages(d.numPages);
						setCurrentPage((prev) => Math.min(prev, d.numPages));
					}}
				>
					<Page pageNumber={currentPage} />
				</Document>
			</div>

			{file !== "" && (
				<div className="resume__pagination my-1">
					<div className="resume__pagination__item">
						<Button
							variant="link"
							style={{
								color: "#ffffff",
								position: "absolute",
								//paddingTop:"-30px",
								marginLeft: "-16px",
								marginTop: "-1px",

								visibility: currentPage <= 1 ? "hidden" : "visible",
							}}
							onClick={() => {
								setCurrentPage((prev) => prev - 1);
							}}
						>
							<FiChevronLeft size={20} />
						</Button>
						<span>
							{currentPage}/{numPages}
						</span>
						<Button
							variant="link"
							style={{
								color: "#ffffff",
								position: "absolute",
								marginTop: "-1px",
								visibility: currentPage >= numPages ? "hidden" : "visible",
							}}
							onClick={() => {
								setCurrentPage((prev) => prev + 1);
							}}
						>
							<FiChevronRight size={20} />
						</Button>
						{/* {file && (
							<Button
								variant="link"
								href={file}
								target="_blank"
								style={{
									color: "#ffffff",
									 position:"absolute",
									 top:"5px",
									 marginLeft:"20px",
									 border:" 1px solid #fff",
									 backgroundColor:"#2C3895",
									height:"17px",
									fontSize:"10px",	
									borderRadius:"20px", 
								}}
								download="Resume.pdf"
							>
							<FiDownload size={15} />
								DownLoad AS PDF
							</Button>
						)} */}
						{file && (
							<Button
								className="btn btn-info btn-sm me-2"
								variant="link"
								href={file}
								target="_blank"
								style={{
									padding: "3.5px",
									color: "#ffffff",
									position: "fixed",
									top: "2.4vh",
									right: "13.5vw",
									backgroundColor: "#003399",
									fontSize: "13px",
									zIndex: 1000,
									fontWeight: 600,
									borderRadius: "5px",
									letterSpacing: "0.8px", // Added letter spacing
								}}
								download="Resume.pdf"
								onMouseEnter={(e) => {
									e.target.style.background = "#004d99";
									e.target.style.boxShadow = "0 10px 8px rgba(0, 0, 0, 0.15)";
								}}
								onMouseLeave={(e) => {
									e.target.style.background = "#003399",
										e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
								}}
							>
								<FiDownload size={13} style={{ marginRight: "5px" }} />
								Download PDF
							</Button>
						)}

						{/* {						<Button
							variant="link"
							onClick={() => setShowSelectTemplate(true)} 
							className="btn-sm ps-3">
							<FiGrid size={20} /> Change Template
						</Button>					}	 */}
					</div>
				</div>
			)}
		</>
	);
};
