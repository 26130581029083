import React, { useState } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RiPencilLine } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DragDrop.css";

const CustomSection = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const [title, setTitle] = useState(false);
  const [loading, setLoading] = useState(false);
  const customSections = watch("customSection");

  const handleMouseOver = () => {
    setTitle(true);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedFields = Array.from(fields);

    const [movedItem] = reorderedFields.splice(source.index, 1);

    reorderedFields.splice(destination.index, 0, movedItem);

    remove();
    reorderedFields.forEach((field) => {
      append(field);
    });
  };

  const handleAddCustomSection = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve,500));
      append({});
    } catch (error) {
      console.error("Error adding activity:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="resume-builder__section">
      <h2>Customized Section</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="customSections">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="custom-sections-list"
            >
              {fields.map((field, index) => {
                const {
                  section_name,
                  city,
                  start_date,
                  end_date,
                  custom_title,
                  description,
                } = customSections?.[index] || {};
                const displayDate = [];
                if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
                if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));

                return (
                  <Draggable key={field.key} draggableId={field.key} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={cx("resume-builder__section__card p-0")}
                      >
                        <input
                          type="hidden"
                          id={`customSection[${index}].id`}
                          name={`customSection[${index}].id`}
                          defaultValue={field.id}
                          {...register(`customSection[${index}].id`)}
                        />
                        <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
                          <h1>
                            <Controller
                              control={control}
                              name={`customSection[${index}].custom_title`}
                              render={({ field }) => (
                                <InputField
                                  type="text"
                                  defaultValue="Untitled"
                                  style={{
                                    width: "150px",
                                    fontWeight: "900",
                                    color: "#2c3a4b",
                                    backgroundColor: "white",
                                    border: "0.25px solid black",
                                  }}
                                  {...field}
                                />
                              )}
                            />
                            <Button
                              variant="link"
                              type="button"
                              style={{ fontSize: "28px", marginLeft: "8px" }}
                              onMouseOver={handleMouseOver}
                              disabled={title}
                            >
                              <RiPencilLine />
                            </Button>
                          </h1>

                          <Accordion.Item
                            as={"div"}
                            variant="link"
                            eventKey={`${field.key}`}
                            className="resume-builder__section__card--title"
                          >
                            <Accordion.Header className="w-100">
                              <div className="m-0">
                                <p className="m-0">
                                  {section_name
                                    ? `${section_name} `
                                    : section_name || "(Not Specified)"}
                                </p>
                                {displayDate.length > 0 && (
                                  <Label className="mb-0">{displayDate.join(" - ")}</Label>
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body eventKey={`${field.key}`}>
                              <>
                                <Row className="mb-3">
                                  <Col>
                                    <Label>Projects , Sports and Achievements.</Label>

                                    <Controller
                                      control={control}
                                      name={`customSection[${index}].section_name`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                      }) => (
                                        <InputField
                                          stretch
                                          placeholder=" Activity Name  "
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>

                                  <Col>
                                    <Label>City</Label>
                                    <Controller
                                      control={control}
                                      name={`customSection[${index}].city`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                      }) => (
                                        <InputField
                                          stretch
                                          placeholder="Enter city"
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Row>
                                      <Col xs={6} className="pr-1">
                                        <Label>Start Date</Label>
                                        <Controller
                                          control={control}
                                          name={`customSection[${index}].start_date`}
                                          render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                            formState,
                                          }) => (
                                            <InputField
                                              type="date"
                                              stretch
                                              onBlur={onBlur}
                                              onChange={onChange}
                                              checked={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      </Col>

                                      <Col xs={6} className="pl-1">
                                        <Label>End Date</Label>
                                        <Controller
                                          control={control}
                                          name={`customSection[${index}].end_date`}
                                          render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { invalid, isTouched, isDirty, error },
                                            formState,
                                          }) =>
                                            end_date === null ? (
                                              <InputField
                                                stretch
                                                value="Present"
                                                readOnly
                                              />
                                            ) : (
                                              <InputField
                                                type="date"
                                                min={start_date}
                                                stretch
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                checked={value}
                                                inputRef={ref}
                                              />
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Label>Description</Label>
                                    <Controller
                                      control={control}
                                      name={`customSection[${index}].description`}
                                      render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                      }) => (
                                        <RTE
                                          value={value}
                                          onBlur={onBlur}
                                          onChange={onChange}
                                          checked={value}
                                          inputRef={ref}
                                        />
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <Button
                          variant="link"
                          disabled={deleting === index}
                          className="resume-builder__section__card--delete"
                          onClick={async () => {
                            try {
                              setDeleting(index);
                              if (field.id) {
                                await Api.delete(`${ENDPOINTS.DELETE_CUSTOMDATA}/${field.id}`);
                              }
                              remove(index);
                            } finally {
                              setDeleting(null);
                            }
                          }}
                        >
                          {deleting === index ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <FiTrash2 size={20} />
                          )}
                        </Button>
                        {/* Drag Handle */}
												<div
													{...provided.dragHandleProps}
													className="drag-handle"
												>
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="tooltip-text">Click and drag to move</span>
												</div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Row className="mb-3">
        <Col>
         <Button
                variant="contained"
                className={`btn-add ${loading ? "loading" : ""}`}
                onClick={handleAddCustomSection}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>
                    <FiPlus size={20} /> {fields.length < 1 ? "Add CustomSection" : "Add One More CustomSection"}
                  </>
                )}
              </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CustomSection;
