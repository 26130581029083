import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from "@material-ui/core/Slider";
import "./Language.css"; // Add any necessary CSS

const LANGUAGES_MAP = [
  "Native speaker",
  "Highly proficient",
  "Very good command",
  "Working knowledge",
  "Expert",
];

const PRE_POPULATED_LANGUAGES = [
  "English",
  "Chinese",
  "Hindi",
  "Spanish",
  "French",
  "Arabic",
  "Bengali",
  "Portuguese",
  "Russian",
  "Urdu"
  // Add more languages as needed
];

const Language = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(null);
  const languages = watch("languages");

  const handleLanguageSelect = (language, index) => {
    setSelectedLanguageIndex(index);
    append({ language_name: language, level: 0 });
  };

  return (
    <div className="resume-builder__section">
      <h2>Languages</h2>
      <Label as="p">Select languages to showcase your proficiency.</Label>

      {/* Display pre-populated languages as clickable rectangular boxes */}
      <div className="languages-selection">
        {PRE_POPULATED_LANGUAGES.map((language, index) => (
          <div
            key={index}
            className="language-box"
            onClick={() => handleLanguageSelect(language, selectedLanguageIndex)}
          >
            {language} <FiPlus className="plus-icon" />
          </div>
        ))}
      </div>

      {fields.map((field, index) => {
        const { language_name, level } = languages?.[index] || {};
        return (
          <div key={field.key} className="resume-builder__section__card p-0">
            <input
              type="hidden"
              id={`languages[${index}].id`}
              name={`languages[${index}].id`}
              defaultValue={field.id}
              {...register(`languages[${index}].id`)}
            />
            <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
              <Accordion.Item
                as={"div"}
                variant="link"
                eventKey={`${field.key}`}
                className="resume-builder__section__card--title"
              >
                <Accordion.Header className="w-100">
                  <div className="m-0">
                    <p className="m-0">{language_name || "(Not Specified)"}</p>
                    <Label className="mb-0">{LANGUAGES_MAP[level] || ""}</Label>
                  </div>
                </Accordion.Header>
                <Accordion.Body eventKey={`${field.key}`}>
                  <>
                    <Row className="mb-3">
                      <Col>
                        <Label>Language</Label>
                        {/* Display the selected language */}
                        <Controller
                          control={control}
                          name={`languages[${index}].language_name`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <InputField
                              stretch
                              placeholder="Enter Language"
                              value={value || language_name}
                              onBlur={onBlur}
                              onChange={onChange}
                              inputRef={ref}
                            //disabled
                            />
                          )}
                        />
                      </Col>
                      <Col>
                        <Label>Proficiency Level</Label>
                        <Controller
                          control={control}
                          name={`languages[${index}].level`}
                          render={({ field: { onChange, value } }) => (
                            <Slider
                              min={0}
                              max={4}
                              value={value}
                              onChange={(e, val) => onChange(val)}
                              style={{ color: "#007bff" }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Button
              variant="link"
              disabled={deleting === index}
              className="resume-builder__section__card--delete"
              onClick={async () => {
                try {
                  setDeleting(index);
                  if (field.id) {
                    await Api.delete(
                      `${ENDPOINTS.DELETE_LANGUAGE}/${field.id}`
                    );
                  }
                  remove(index);
                } finally {
                  setDeleting(null);
                }
              }}
            >
              {deleting === index ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <FiTrash2 size={20} />
              )}
            </Button>
          </div>
        );
      })}

      <Row className="mb-3">
        <Col>
          {/* <Button
            className="text-info fs-4"
            variant="link"
            type="button"
            onClick={() => append({ level: 0 })}
            style={{ fontWeight: 'bold' }}
          >
            <FiPlus />
            {fields.length < 1 ? " Add language" : " Add one more language"}
          </Button> */}

          <Button
            className="text-info fs-4"
            variant="link"
            type="button"
            onClick={() => append({ level: 0 })}
            style={{
              fontWeight: 'bold',
              transition: 'color 0.2s, transform 0.2s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#17a2b8';
              e.currentTarget.style.transform = 'scale(1.1)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.transform = 'scale(1)';
            }}
          >
            <FiPlus />
            {fields.length < 1 ? ' Add language' : ' Add one more language'}
          </Button>

        </Col>
      </Row>
    </div>
  );
};

export default Language;
